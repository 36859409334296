<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- Content -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                <TabView v-model:activeIndex="pageIndex">
                    <TabPanel header="1. View">
                        <DataTable :value="manual" :loading="loading" class="w-full">
                            <Column field="id" header="Search Id" ></Column>
                            <Column field="name" header="Asset Name" ></Column>
                            <Column field="totallinks" header="Total Links" ></Column>
                            <Column field="filtered" header="Filtered Links" ></Column>
                            <Column field="final" header="Successful Links" ></Column>
                        </DataTable>

                        <Button class="w-full mt-2" label="Proceed" @click="nextPage" />
                    </TabPanel>

                    <TabPanel header="2. Asset">
                        <p class="flex font-bold capitalize text-left">Client Selection</p>
                        <MultiSelect v-model="selectedClients" :options="clients" optionLabel="name" placeholder="Select Clients" class="w-full" />

                        <p class="flex font-bold capitalize text-left">Type Selection</p>
                        <MultiSelect v-model="selectedTypes" :options="types" optionLabel="name" placeholder="Select Types" class="w-full" />

                        <p class="flex font-bold capitalize text-left">Subtype Selection</p>
                        <MultiSelect v-model="selectedSubtypes" :options="subtypes" optionLabel="name" placeholder="Select Subtypes" class="w-full" />

                        <div class="pt-3"/>

                        <div class="grid">
                            <div v-show="!loading" v-for="asset in displayedAssets" v-bind:key="asset.id" class="col-6 md:col-3 lg:col-2 xl:col-1">
                                <div v-bind:class="[(submittedManualNames.includes(asset.name)?'surface-300':''), (asset.daysago < 14 ? 'border-3 border-blue-200' : '')]"
                                     class="border-round shadow-2 cursor-pointer " @click="toggleAsset(asset)">
                                    <div class="image-container px-2 pt-2">
                                        <img style="object-fit: cover; width: 100%; aspect-ratio: 0.675;" class="border-round" :alt="'Poster for' + asset.name" v-bind:src="asset.image"/>
<!--                                        <div v-if="asset.daysago < 300 && !submittedManualNames.includes(asset.name)" class="overlay-text w-full top-0 border-round-top">-->
<!--                                            <i class="pi pi-eye  fadein animation-duration-1000 animation-iteration-infinite"/>-->
<!--                                        </div>-->
                                    </div>

                                    <div class="flex flex-column justify-content-between">
                                        <p class="ml-2 text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ asset.name }}</p>
                                        <p class="-mt-2 ml-2 text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ asset.client }}</p>
                                        <p class="-mt-2 ml-2 text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ asset.imdbHandle }}</p>
                                        <p class="-mt-2 ml-2 text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ asset.date }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel header="3. Find Links" :disabled="selectedAsset.id===null">
                        <!-- Asset information -->
                        <p class="flex font-bold capitalize text-left text-lg">Asset Information</p>
                        <p class="text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Asset Name: <b>{{ selectedAsset.name }}</b></p>
                        <p class="text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Client Name: {{ selectedAsset.client }}</p>
                        <p class="text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">IMDB Handle: {{ selectedAsset.imdbHandle }}</p>
                        <p class="text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Notice: {{ selectedAsset.notice }}</p>

                        <Accordion v-if="selectedAsset.type === 'Product'" active-index="-1">
                            <AccordionTab header="Marketplace Lookup">
                                <div v-for="term in marketplaceSearchTerms" v-bind:key="term" class="w-full">
                                    <div class="text-2xl font-bold">{{ term.name }}</div>
                                    <div v-for="link in term.links" v-bind:key="link" class="text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                        <a :class="getLinkClass" :href="link" target="_blank">{{ link }}</a>
                                    </div>
                                </div>
                            </AccordionTab>
                            <AccordionTab header="SmartVault">
                                <p class="pt-2 font-bold">Image Vault</p>
                                <div v-if="assetImages.length > 0" class="grid">
                                    <div v-for="img in assetImages" v-bind:key="img.Id" class="col-3">
                                        <div class="relative w-full">
                                            <img style="width: 100%; object-fit: cover; aspect-ratio: 1" :src="img.image"/>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>

                        <p class="flex font-bold capitalize text-left text-lg">Find Links</p>

                        <Textarea class="w-full" v-model="pastedLinks" rows="20" placeholder="Paste links here"
                            @paste="onPaste" :autoResize="false" ref="manual-pasted-links"/>

                        <Button class="w-full mt-2" label="Submit" @click="submit" :disabled="pastedLinks.length===0"/>
                    </TabPanel>
                </TabView>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Manual",

    data() {
        return {
            loading: false,
            manual: [],
            pageIndex: 1,
            activeAssets: [],
            displayedAssets: [],
            selectedAsset: {
                id: null,
                name: null,
                image: null,
                type: null,
                client: null,
                imdbHandle: null,
                date: null,
            },
            pastedLinks: "",
            submittedManualNames: [],

            selectedClients: [],
            selectedTypes: [],
            selectedSubtypes: [],

            clients: [],
            types: [],
            subtypes: [],

            providerDomains: [],

            // For marketplace assistance
            assetImages: [],
            marketplaceSearchTerms: []
        }
    },

    computed: {
        filters() {
            return this.selectedClients.concat(this.selectedTypes).concat(this.selectedSubtypes);
        },

        getLinkClass() {
            if (this.$store.state.theme === 'light') {
                return 'light-theme-link';
            } else {
                return 'dark-theme-link';
            }
        }
    },

    mounted() {
        this.getManual();
        this.getAssets();
        this.getContentProviders();

        this.getFilters();
    },

    methods: {
        getManual() {
            this.loading = true;
            this.$factory.manual.getJobs().then((res) => {
                this.manual = res;
                this.submittedManualNames = res.map((job) => job.name);
                console.debug(res)
            }).catch((err) => {
                console.log(err);
                this.$emitter.emit('bad-toast', 'Failed to get manual jobs');
            }).finally(() => {
                this.loading = false;
            });
        },

        getAssets() {
            // TODO in the future get the assets from the server according to this users schedule
            let activeAssets = this.$store.getters.getActiveAssetData.filter(
                asset => !asset.type.includes("Prospect")
            );
            this.activeAssets = activeAssets;
        },

        getContentProviders() {
            this.$factory.linkRemoval.getProviders().then((response) => {
                response.forEach((provider) => {
                    provider.domains.forEach((domain) => {
                        this.providerDomains.push(domain);
                    });
                });
                console.debug("Domains", this.providerDomains);
            });
        },

        getFilters() {
            let clients = [];
            let types = [];
            let subtypes = [];

            this.$store.getters.getActiveAssetData.forEach(asset => {
                if (asset.type.includes("Prospect")) return;
                clients.push(asset.client);
                types.push(asset.type);
                subtypes.push(asset.subtype);
            });

            clients = [...new Set(clients)];
            types = [...new Set(types)];
            subtypes = [...new Set(subtypes)];

            this.clients = clients.map(client => ({ name: client }));
            this.types = types.map(type => ({ name: type }));
            this.subtypes = subtypes.map(subtype => ({ name: subtype }));

            this.selectedClients = this.clients;
            this.selectedTypes = [{ name: 'Movie' }];
            this.selectedSubtypes = this.subtypes;
        },

        filterAssets() {
            this.displayedAssets = this.activeAssets.filter(asset => {
                let client = this.selectedClients.map(client => client.name).includes(asset.client);
                let type = this.selectedTypes.map(type => type.name).includes(asset.type);
                let subtype = this.selectedSubtypes.map(subtype => subtype.name).includes(asset.subtype);
                return client && type && subtype;
            });
        },

        toggleAsset(asset) {
            this.selectedAsset = asset;

            this.getAssetImages();
            this.generateMarketplaceSearchTerms();

            this.nextPage()
        },

        getAssetImages() {
            console.debug(this.selectedAsset);
            if (this.selectedAsset.type !== 'Product') return;
            this.assetImages = [];
            this.$factory.asset.getAssetImages(this.selectedAsset.id)
                .then(response => {
                    console.debug("Fetched images", response);
                    this.assetImages = response.data;
                })
                .catch(error => {
                    console.error('get images', error)
                    this.$emitter.emit('bad-toast', 'Failed to fetch images from SmartVault');
                })
        },

        generateMarketplaceSearchTerms() {
            this.marketplaceSearchTerms = [];

            // TODO: generate search terms for marketplaces enabled for this asset
            let marketplaces = [
                {
                    name: 'Alibaba',
                    url: 'https://www.alibaba.com/trade/search?SearchText={search}&page={page}',
                    delim: '+',
                },
                {
                    name: 'Aliexpress',
                    url: 'https://www.aliexpress.com/w/wholesale-{search}.html?page={page}',
                    delim: '-',
                },
                {
                    name: 'DHgate',
                    url: 'https://www.dhgate.com/wholesale/search.do?searchkey={search}&pageNum={page}',
                    delim: '+',
                }
            ]

            // Generate search terms for each marketplace
            marketplaces.forEach(marketplace => {
                let marketplaceList = [];

                for (let i = 1; i <= 5; i++) {
                    let searchTerm = marketplace.url
                        .replace('{search}', this.selectedAsset.name.toLowerCase().replaceAll(' ', marketplace.delim))
                        .replace('{page}', i);
                    marketplaceList.push(searchTerm);
                }
                this.marketplaceSearchTerms.push({
                    name: marketplace.name,
                    links: marketplaceList
                })
            });

            console.debug(this.marketplaceSearchTerms);
        },

        nextPage() {
            this.pageIndex++;
        },

        onPaste(event) {
            // TODO parse the pasted links and add them to the list
            event.preventDefault();
            let pasted = event.clipboardData.getData('text').replace(/\/b\/\?/g, "");
            // let pasted = event.clipboardData.getData('text').replace(/ /g, "\n");
            this.pastedLinks += pasted + "\n";
            console.debug(this.pastedLinks)
            this.$refs["manual-pasted-links"].selectionEnd = this.pastedLinks.length
        },

        submit() {
            let submissionLinks = this.pastedLinks.replaceAll("\r", "").split("\n");
            submissionLinks = submissionLinks.filter(link => link.trim() !== ""); // remove empty lines
            submissionLinks = [...new Set(submissionLinks)]; // remove duplicates
            let baseRegex = new RegExp(/^http(s|):\/\/.*\/.+/gm, "i");
            submissionLinks = submissionLinks.filter(link => baseRegex.test(link)); // verify links are urls

            // Check all links are within any of the domains
            if (this.selectedAsset.type === 'Product') {
                let domainsRegex = new RegExp(`^http(s|)://(${this.providerDomains.join("|")})/.+$`, "im");
                submissionLinks = submissionLinks.filter(link => domainsRegex.test(link));
                // For each link remove ? and everything after it
                submissionLinks = submissionLinks.map(link => link.split("?")[0]);
            }

            console.debug("Sending links", submissionLinks)

            let requestBody = {
                username: this.$store.getters.getUserInfo.username,
                assetId: this.selectedAsset.id,
                assetName: this.selectedAsset.name,
                links: submissionLinks,
            }

            console.debug(requestBody);

            this.$factory.manual.submitLinks(requestBody).then((res) => {
                console.debug(res);
                this.$emitter.emit('good-toast', 'Successfully submitted links');

                // reset the form
                this.selectedAsset = {
                    id: null,
                    name: null,
                    image: null,
                    client: null,
                    imdbHandle: null,
                    date: null,
                };
                this.pastedLinks = "";
                this.pageIndex = 1;
                this.getManual();

            }).catch((err) => {
                console.log(err);
                this.$emitter.emit('bad-toast', 'Failed to submit links');
            }).finally(() => {
                this.loading = false;
            });
        }
    },

    watch : {
        filters() {
            this.filterAssets();
        }
    }
}
</script>

<style scoped>
.image-container {
    position: relative;
    display: inline-block;
}

.image-container img {
    display: block;
    width: 100%;
    height: auto;
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -0%);
    background-color: #3B82F6;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    text-align: center;
}

.light-theme-link {
    color: black;
}
.light-theme-link:visited {
    color: darkgray;
}
.dark-theme-link {
    color: white;
}
.dark-theme-link:visited {
    color: lightgray;
}
</style>
